<template>
    <RouterView />
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router';
import { setCookie } from '@/__aurora/hooks/useCookies';
import { useHead } from '@unhead/vue'

const route = useRoute();

useHead({ 
    script: [ 
        {
            src: '/js/metrika.js', 
        },
        {
            src: 'https://app.uiscom.ru/static/cs.min.js?k=f1ySe_qkmz4oWoKKF9TAtFdpePHBp8Qm',
            async: true
        }
    ]}, 
    { mode: 'client' }
);

onMounted(()=> {
    if (Object.keys(route.query).length) {
        for (const key in route.query) {
            setCookie(key, route.query[key])
        }
    }
})
</script>

